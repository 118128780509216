import {Component, Input, OnInit} from '@angular/core';
import {HrefType} from '../../model/href-type';
import {faAt, faExternalLinkAlt, faDownload} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-href-link',
    templateUrl: './href-link.component.html',
    styleUrls: ['./href-link.component.scss']
})
export class HrefLinkComponent {

    @Input() type: HrefType;
    @Input() href: string;
    @Input() titleText: string;
    @Input() description?: string;

    faEmail = faAt;
    faExternalLink = faExternalLinkAlt;
    faDownload = faDownload;

    isExternal() {
        return this.type === HrefType.EXTERNAL;
    }

    isFile() {
        return this.type === HrefType.FILE;
    }

    isEmail() {
        return this.type === HrefType.EMAIL;
    }
}
