import { Component, OnInit } from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faMicroscope} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-science',
  templateUrl: './science.component.html',
  styleUrls: ['./science.component.scss']
})
export class ScienceComponent  extends Base implements OnInit {


  constructor(navigationService: NavigationService) {
    super(navigationService);
  }

  ngOnInit() {
    this.navigationItem = this.addToNavigation('Věda', 'science', faMicroscope);
  }

}
