<div [id]="navigationItem.scrollTo" class="section">
    <div class="container">
        <div class="section-header">
            <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">{{ navigationItem.title }}</h2>
        </div>
        <div class="row">
            <div class="col-lg-7 col-12">
                <h3 class="btn btn-subtitle wow fadeInDown animated offset-lg-4 col-lg-4 col-sm-10 offset-sm-1 btn-subtitle-cursor">
                    Muži</h3>
                <div class="col-12 content">
                    Příčinou přitažlivosti ke stejnému pohlaví u mužů může být to, že jim chyběl otec nebo měli s otcem
                    negativní
                    nebo obtížný vztah, protože byl velmi tvrdý nebo chyběl citový vztah, neprojevoval synovi lásku.
                    Maminka byla
                    naopak příliš ochranářská, dělala si o syna příliš velké starosti a rozmazlovala ho. Když měla
                    problémy s
                    manželem, protože byl například alkoholik nebo násilník, povýšila syna na místo svého partnera.
                    Hovořila pak o
                    manželovi negativně, jak s ním trpí, jak je špatný, a to mohlo narušit identifikaci dítěte s
                    mužstvím. Další
                    možností může být to, že dítě bylo slabé nebo nemocné a potřebuje oporu a být rozmazlované. To může
                    přinést
                    obtíže, protože mužský svět je soutěživý, dítě musí být silné, dobré ve sportu, aby obstálo a
                    prosadilo se.
                    Pokud je slabé, tak to nedokáže a necítí se být mužem. Muž, který cítí přitažlivost ke stejnému
                    pohlaví, má
                    pocit, že mu chybí mužství. Hledá lásku od otce, kterou neměl. Potřebuje muže, který by ho měl rád.
                    Díky této
                    lásce se pak bude cítit hoden být mužem. Potřebuje cítit přilnutí k muži, aby se mohl cítit mužem.
                    Mužské
                    symboly a především penis se pro něho stávají obsesivní. Je posedlý tím, aby viděl obrázek nahého
                    muže či
                    mužskou pornografii, což je způsobeno komplexem chybějícího mužství. Proto se v terapii pak pracuje
                    především
                    na vnímání mužství. "Jsi muž jako kdokoliv jiný, nechybí ti nic. Co hledáš? To, co má muž, máš i
                    ty." Pracuje
                    se s nevědomím a terapeut se snaží poukázat na deficit vztahu s otcem nebo s matkou. Jsou to silná
                    nevědomá
                    zranění a terapeut se snaží tyto vztahy obnovit na úrovni nevědomí. Největší problém u těchto osob
                    není
                    sexuální tendence, ale závislost na sexu. Většina homosexuálních jedinců upadá do velké promiskuity
                    a mají
                    vztahy s mnoha partnery. Je to posedlost rodící se z emočních zranění ústící do závislosti.
                    Úspěšnost terapie
                    pak závisí na počtu vztahů, které osoba měla a na jejím věku. Čím je mladší a čím méně vztahů měla,
                    tím
                    snadněji překoná svou závislost. Pokud taková osoba je motivovaná a dobře spolupracuje při terapii,
                    tak po
                    několika měsících začne cítit přitažlivost k opačnému pohlaví. Všimne si toho, že se u něho něco
                    mění a
                    přestává cítit přitažlivost ke stejnému pohlaví. Není to jednoduché, záleží nakolik osoba upadla do
                    závislosti. Motivace chtít se změnit, musí být při terapii silná.
                </div>
                <div class="col-12 content">
                    Nejčastější přispívající rizikové faktory, které vedou k rozvoji přitažlivosti ke stejnému pohlaví u
                    mužů:
                    <ul>
                        <li class="smaller">zraněná pohlavní identita</li>
                        <li class="smaller">šikana</li>
                        <li class="smaller">hladovění po otci</li>
                        <li class="smaller">nepřirozený vztah s matkou</li>
                        <li class="smaller">sexuální zneužívání, nepřípustné dotyky</li>
                        <li class="smaller">pornografie</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <h3 class="btn btn-subtitle wow fadeInDown animated offset-lg-4 col-lg-4 col-sm-10 offset-sm-1 btn-subtitle-cursor">
                    Ženy</h3>
                <div class="col-12 content">
                    U vzniku ženské homosexuality je známo, že 70% žen s přitažlivostí ke stejnému pohlaví prožilo
                    traumatické
                    zneužití, mají proto strach ze vztahu s mužem a utíkají se k citovému vztahu s ženou. Od
                    homosexuálních mužů
                    se především liší tím, že touží mít trvalou partnerku. Zatímco mužská sexualita je vizuální,
                    genitální, ženská
                    je více citová. Proto v afektivním ženském prostředí děvče, které mělo špatný vztah s maminkou má
                    potřebu
                    hledat tento vztah s jinými ženami. Vztahy jež se mezi ženami vytvoří nejsou sexuální, ale
                    afektivní, citové
                    zaměřené na přilnutí a tudíž velmi žárlivé. Ukazuje se u nich velká potřeba mateřské lásky, která
                    jim chyběla.
                    Dalším možným původem homosexuality u žen jsou i případy děvčat, které měly depresivní matku nebo
                    byla příliš
                    slabá a děvčata se s ní tak nedokázala identifikovat a raději se identifikovala s otcem, který byl
                    silnější,
                    dominantnější. Také se vyskytují případy otců, kteří si přáli místo dcery syna a vychovávají děvče
                    jako
                    chlapce. A pokud chtěla být dcera otcem přijata, musela se chovat jako chlapec a nakonec se
                    identifikovala s
                    chlapcem. Mužská homosexualita je hodně silná, podmíněná a velmi jasná. U žen je homosexualita
                    daleko
                    citovější, ne tolik vyhraněná. Je hodně žen, které mají homosexuální tendence, ale mohou mít dobře
                    heterosexuální vztah či žít v manželství, aniž by měly problémy.
                </div>
            </div>
            <div class="col-12">
                <h3 class="btn btn-subtitle wow fadeInDown animated offset-lg-4 col-lg-4 col-sm-10 offset-sm-1 btn-subtitle-cursor">
                    Současná terapie homosexuality</h3>
                <div class="content">
                    Původně se s klienty pracovalo pomocí konverzní terapie. Dnes někteří terapeuti, kteří s klienty s
                    nechtěnou
                    homosexualitou pracují, se zaměřují na rodinné vazby stejně jako u klientů s jinými problémy. Řeší
                    jejich
                    vztah
                    s otcem, matkou, přijetím a sebevědomím. Již na základě této klasické terapie dochází u klientů po
                    vyřešení
                    rodinných vazeb jako vedlejší produkt ke změně v tom, že je přestanou přitahovat jedinci stejného
                    pohlaví a
                    začnou je přitahovat jedinci pohlaví opačného. Dále se pracuje pomocí reparativní terapie, kterou
                    vyvinul v
                    USA
                    Dr. Joseph Nicolosi. Dalším přístupem je narativní terapie a nejnovějším směrem je reintegrativní
                    terapie.
                </div>
            </div>
            <div class="col-12 content">
                Do češtiny je přeložena publikace
                <app-href-link
                    href="assets/terapie_homosexuality.pdf" [type]="typeFile"
                    titleText="Terapie homosexuality – Rádce pro postižené a poradce"></app-href-link>
                , kterou napsal
                nizozemský křesťanský psycholog Gerard J. M. van den Aardweg. Tato kniha má dvě části. V první se autor
                zabývá
                názory na homosexualitu a v druhé poskytuje metodu - praktická pravidla pro terapii, jak sám krok za
                krokem na
                sobě terapii podstoupit.
            </div>
            <div class="col-12 content">
                Do slovenštiny je přeložena publikace
                <app-href-link
                    href="https://www.luc.sk/homosexualita-nemus%C3%AD-by%C5%A5-osudom" [type]="typeExternal"
                    titleText="Homosexualita nemusí byť osudom"></app-href-link>
                , kterou napsal americký psychoterapeut Dr. Joseph Nicolosi. Jedná se o přepis terapeutických rozhovorů s klienty. Je určena zejména pro muže, kteří se nedokáží a nechtějí ztotožnit s homosexualitou, jako se svojí osobní identitou, a kteří v hloubi duše cítí, že chtějí jít po cestě vniřního osvobození.
            </div>
        </div>
    </div>
</div>
