<div [id]="navigationItem.scrollTo" class="section">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">{{navigationItem.title}}</h2>
    </div>
    <div class="row">
      <div class="col-12 content">
        V médiích se nacházejí různé informace o homosexualitě. Které z nich jsou však vědecké? A které z nich vyjadřují
        pouze osobní názory? Můžete si vyslechnout přednášky odborníků na nové webové stránce <app-href-link
        href="http://www.iftcc.org" [type]="typeExternal" titleText="www.iftcc.org"></app-href-link>. Najdete tam
        i film Voices of the Silenced (Hlasy umlčených) s titulkami ve 12 jazycích, včetně slovenského. Komentáře
        odborníků doplněné osobními příběhy dělají film zajímavým.
      </div>
      <div class="col-12 content">
        Odborníci z <span class="help"
                          tooltip="Mezinárodní federace pro umožnění terapeutické a poradenské volby">IFTCC</span>
        nabízí možnosti vzdělávání ve formě provázení osob, které prožívají homosexuální přitažlivost (<app-href-link
        href="mailto:info@iftcc.org" [type]="typeEmail" titleText="info@iftcc.org"></app-href-link>).
        Pro Českou a Slovenskou republiku je možný <app-href-link href="mailto:info@homosexualita.sk" [type]="typeEmail" titleText="kontakt přes email"  description="info@homosexualita.sk"></app-href-link>
      </div>
      <div class="col-12 content">
        Kam se mohou obrátit ženy a muži prožívající homosexuální sklony, pokud chtějí najít místo přijetí, osobní
        komunikace, či duchovní podpory? Mohou kontaktovat například
        <app-href-link href="http://www.priestorprijatia.sk" [type]="typeExternal" titleText="Občanské sdružení Priestor prijatia"></app-href-link> působící v Bratislavě
        nebo Linku Valentin, která organizuje duchovní obnovy dvakrát
        do roka. Jsou určené křesťanům s homosexuálními sklony a těm, kteří prožívají zranění v oblasti vztahů, citů a
        sexuality mají problém se identifikovat s vlastním pohlavím. Jsou určené i jejich přátelům a rodinným
        příslušníkům. Linka Valentin působí už dvacet let jako pastorační aktivita Řeckokatolické církve v Košicích.
        Jejich duchovní obnovy navštěvují lidé z Čech, Slovenska a dalších zemí Evropy - <app-href-link
        href="mailto:valentinskespolocenstvo@centrum.sk" [type]="typeEmail" titleText="Kontakt"></app-href-link>)
      </div>
      <div class="col-12 content">
        O duchovním provázení osob s homosexuálním sklonem v Římě informoval Giuseppe Marciante (<app-href-link
        href="https://www.courageitalia.it/wp-content/uploads/2017/06/Timone-intervista-Marciante.pdf" [type]="typeExternal" titleText="slovenský
        překlad"></app-href-link>).
        Věnuje se pastoraci osob s přitažlivostí ke stejnému pohlaví v rámci
        <app-href-link href="http://www.CourageRC.org" [type]="typeExternal" titleText="hnutí CourageRC"></app-href-link>. Založil jej otec John F.
        Harvey, OSFS ještě v roce 1980 v USA a dnes působí již v mnoha zemích světa. V Čechách a na Slovensku se tato
        forma duchovního provázení zatím nerealizuje. Věříme, že se v budoucnu otevře i tato možnost, která v sobě nese
        bohatství mnohaletých zkušeností.
      </div>
      <div class="col-12 content">
        Vědecké informace k tématu homosexuality a identity získáte také na stránkách:
        <app-href-link href="http://www.homosexualita.sk" [type]="typeExternal" titleText="www.homosexualita.sk"></app-href-link>,
        <app-href-link href="http://www.dijg.de" [type]="typeExternal" titleText="www.dijg.de"></app-href-link>
        či <app-href-link href="https://www.youtube.com/watch?v=G0KgJABgRf0" [type]="typeExternal" titleText="youtube kanál"></app-href-link>
      </div>
      <div class="col-12">
        <h3 class="btn btn-subtitle wow fadeInDown animated offset-lg-4 col-lg-4 col-sm-10 offset-sm-1 btn-subtitle-cursor">Speciálně pro muže</h3>
        <div class="content">Do Evropy přicházejí muži z jiných kontinentů, aby sdíleli své osobní zkušenosti a byli
          přínosem pro ty, kteří hledají cestu, jak si posílit mužství. Někteří možná hledají i odpověď na to, co je
          homosexualita, a hledají cestu, jak jít dále s nechtěnou přitažlivostí ke stejnému pohlaví. Journey into
          Manhood (Cesta do mužnosti) funguje od roku 2002, takže už má za sebou nějaké zkušenosti. Různé aktivity, kde
          se můžete dozvědět podstatné informace o tématu najdete na
          <app-href-link href="http://www.brothersroad.org" [type]="typeExternal" titleText="www.brothersroad.org"></app-href-link>.
        </div>
      </div>
      <div class="col-12 content">
        Organizací, která funguje už od roku 1984 a věnuje se mužům je ManKindProject (projekt mužnosti). Víkendové New
        Warrior tréninky (Výcvik nového bojovníka) organizují po celém světě pro všechny muže, bez ohledu na náboženské
        vyznání či sexuální orientaci. Více o cíli a poslání této organizace najdete na webu
        <app-href-link href="http://www.mankindproject.org" [type]="typeExternal" titleText="www.mankindproject.org"></app-href-link>
      </div>
      <div class="col-12 content">
        Není však třeba jezdit daleko za mužskou spiritualitou. Stačí zažít netradiční duchovní každoroční víkend v
        ekumenickém duchu v Nesměři. Projekt, který čeští muži přejali přímo od amerického františkána Richarda Röhra
        vznikl původně jako reakce na potřebu posilování mužské identity v moderní společnosti. Je velmi oblíbený. V
        roce 2018 se uskutečnil již 15. ročník a prošlo jím víc než 1200 mužů - <app-href-link href="http://www.familia.cz/chlapi/akce" [type]="typeExternal" titleText="přihláška pro rok 2019"></app-href-link>
      </div>
      <div class="col-12 content">
        <h3 class="btn btn-subtitle wow fadeInDown animated offset-lg-4 col-lg-4 col-sm-10 offset-sm-1 btn-subtitle-cursor">Další odkazy</h3>
        <ul>
          <li>
            <app-href-link href="https://iftcc.org/sk" [type]="typeExternal"
               description="Mezinárodní federace pro umožnění terapeutické a poradenské volby" titleText="IFTCC The International
              Federation For Therapeutical and Counselling Choice"></app-href-link>
          </li>
          <li>
            <app-href-link href="https://www.priestorprijatia.sk/" [type]="typeExternal" titleText="Pomoc na Slovensku"></app-href-link>
            -
            <app-href-link href="mailto:valentinskespolocenstvo@centrum.sk" [type]="typeEmail" titleText="" description="valentinskespolocenstvo@centrum.sk"></app-href-link>
          </li>
          <li>
            <app-href-link href="https://www.voicesofthesilenced.com/" [type]="typeExternal" titleText="Příběhy osob, které změnily sexuální orientaci" description="Voices of the Silenced (Hlasy umlčených)"></app-href-link>
          </li>
          <li>
            <app-href-link href="https://www.josephnicolosi.com/" [type]="typeExternal" titleText="Dr. Joseph Nicolosi"></app-href-link>
          </li>
          <li>
            <app-href-link href="https://www.reintegrativetherapy.com" [type]="typeExternal" titleText="Reintegrativní terapie"></app-href-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
