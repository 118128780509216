import {Component, OnInit} from '@angular/core';
import {ItemNavigation} from '../../model/item-navigation';
import {NavigationService} from '../../service/navigation/navigation.service';
import {environment} from '../../../environments/environment';
import {faBars} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    showHamburgerMenu;
    items: ItemNavigation[];
    faBars = faBars;

    constructor(private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.items = this.navigationService.getItems();
        this.navigationService.eventByClick = false;
        this.showHamburgerMenu = false;
        this.setActiveOnScroll();
    }

    navigateTo(target: ItemNavigation) {
        this.navigationService.eventByClick = true;
        this.navigationService.setItemAsActive(target);
        document.querySelector('#' + target.scrollTo)?.scrollIntoView({behavior: "smooth"});
        const self = this;
        setTimeout(function () {
            self.navigationService.eventByClick = false;
        }, environment.navigation.duration);

        if (this.showHamburgerMenu) {
            this.showHamburgerMenu = false;
        }
    }

    setActiveOnScroll() {
        const self = this;
        this.navigationService.getItems().forEach(function (item) {
            const elem = document.getElementById(item.scrollTo);
            if (elem != null && elem.getBoundingClientRect().top > -5 && elem.getBoundingClientRect().top < 5) {
                self.navigationService.setItemAsActive(item);
            }
        });
    }

    getNavClass(): string {
        return this.navigationService.showNavigationBackground ? 'menu-bg' : '';
    }

}
