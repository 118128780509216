<div [id]="navigationItem.scrollTo" class="section">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">{{navigationItem.title}}</h2>
    </div>
    <div class="row">
      <div class="col-12 content">
        Hormonální původ homosexuality nebyl jednoznačně prokázaný. Byla vytvořena hypotéza, že homosexuální jedinci
        mají menší hladinu mužského hormonu testosteronu oproti heterosexuálním jedincům. Bisexuální jedinci mají naopak
        vyšší hladinu tohoto hormonu. Není však jasné, proč to tak je.
      </div>
      <div class="col-12 content">
        Ani vrozený původ homosexuality nebyl jednoznačně prokázaný. Asociace amerických psychiatrů hovoří o tom, že o
        původu homosexuality není možné udělat jednoznačný závěr. Není možné prokázat, že tomu tak je, ale zároveň není
        možné vyvrátit, že tomu tak není. Studie totiž při svých výzkumech docházely k oběma závěrům. V 90. letech
        proběhla známá studie na jednovaječných identických dvojčatech, která dospěla k závěru, že pokud je jedno z
        dvojčat homosexuální, pravděpodobnost, že druhé bude také homosexuální, je okolo 70%. Při výzkumu na
        dvojvaječných dvojčatech pak pravděpodobnost, že druhé z dvojčat bude homosexuální vyšla okolo 30%. Odborníci,
        kteří pak tyto studie posuzovali, dospěli k závěru, že výsledky jednoznačnou vrozenost homosexuality
        neprokazují, protože pravděpodobnost u jednovaječných dvojčat by se musela blížit ke 100%. Jednovaječná dvojčata
        mají stejnou genetickou výbavu, takže pokud to není 100%, nejedná se o genetickou záležitost. Na druhé straně
        jednovaječná dvojčata bydlí ve stejném prostředí a mají stejnou výchovu a podobnou zkušenost, takže je
        pravděpodobné, že pokud je jeden homosexuální, bude i druhý. Tyto úvahy pak vedou k závěru, že vliv na původ
        homosexuality má spíše prostředí.
      </div>
      <div class="col-12 content">
        Závěr tedy je, že se neví, zda je homosexualita vrozená nebo ne. Americká psychologická asociace až do roku 2008
        zastávala názor, že homosexualita je determinovaná biologicky. Po tomto roce byl však přehodnocen a nyní je
        zastáván mnohem komplexnější názor, že homosexualita je způsobena jakousi kombinací prvků vrozených a získaných.
        V současnosti všeobecně šířené tvrzení, že homosexualita je vrozená a nedá se léčit, je "politicky korektní"
        tvrzení přijaté na nátlak <span class="help" tooltip="zkratka označující lesby, gaye, bisexuály a transgender osoby">LGBT</span> lobbistických organizací a je společensky nepřípustné s ním polemizovat, když
        odporuje vědeckým závěrům.
      </div>
      <div class="col-12 content">
        Studie <span class="help" tooltip="Závěry z oblasti biologie, psychologie a společenských věd">Findings from the Biological, Psychological, and Social Scieces</span> autorů: Lawrence S. Mayer, M.B., M.S., Ph.D. a Paul R. McHugh, M.D. uveřejněná na podzim roku
        2016 v časopisu The New Atlantis – A Journal of Technology & Society, Number 50 ~ Fall 2016 předkládá
        následující závěry:
        <ul>
          <li>
            Chápání sexuální orientace jako vrozené, biologicky neměnné vlastnosti jedinců - názor, že se "takoví
            narodili" - není podloženo vědeckými důkazy
          </li>
          <li>
            Přesto, že existují důkazy, že biologické faktory, jako geny a hormony, mají souvislost se sexuálním
            chováním a přitažlivostí, neexistují u jedinců žádná přesvědčivá vysvětlení biologické příčiny sexuální
            orientace. Co zatím vědci identifikovali, jsou menší rozdíly v strukturách mozku a mozkové aktivitě. Tyto
            neurologické nálezy však neprokazují, zda jsou tyto rozdíly vrozené, či jsou výsledkem působení prostředí a
            psychologických faktorů
          </li>
          <li>
            Dlouhodobé studie adolescentů naznačují, že sexuální orientace může být během života proměnlivá (fluidní),
            přičemž jedna studie odhaduje, že až 80% mužů, kteří jako adolescenti uvedli, že cítí přitažlivost ke
            stejnému pohlaví, to už v dospělosti neuvádí (i když výsledný údaj, do jaké míry tento údaj odráží skutečné
            změny v přitažlivosti ke stejnému pohlaví a nejen artefakty během procesu výzkumu, byl některými vědci
            zpochybněn)
          </li>
          <li>
            V porovnání s heterosexuálními jedinci je u neheterosexuálních jedinců dva až třikrát větší pravděpodobnost,
            že zažili během dětství sexuální zneužívání
          </li>
        </ul>
        Originální text studie -
          <app-href-link href="/assets/atlantis_en.pdf" [type]="typeFile" titleText="anglicky"></app-href-link> a
          <app-href-link href="/assets/atlantis_sk.pdf" [type]="typeFile" titleText="slovensky"></app-href-link>
      </div>
    </div>
  </div>
</div>
