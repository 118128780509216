import {Injectable} from '@angular/core';
import {ItemNavigation} from '../../model/item-navigation';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  items: ItemNavigation[];
  eventByClick: boolean;
  showNavigationBackground: boolean;

  constructor() {
    this.items = [];
    this.showNavigationBackground = false;
  }

  addToNavigation(item: ItemNavigation) {
    this.items.push(item);
  }

  getItems(): ItemNavigation[] {
    return this.items;
  }


  setItemAsActive(item: ItemNavigation) {
    this.resetActiveItems();
    item.active = 'active';
  }

  public resetActiveItems() {
    this.items.forEach(function (item) {
      item.active = '';
    });
  }
}
