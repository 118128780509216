import {Component, OnInit} from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent extends Base implements OnInit {

  constructor(navigationService: NavigationService) {
    super(navigationService);
  }

  ngOnInit() {
    this.navigationItem = this.addToNavigation('Úvod', 'introduction', faInfoCircle);
  }

}
