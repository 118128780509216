import {Component} from '@angular/core';
import {NavigationService} from './service/navigation/navigation.service';
import {environment} from '../environments/environment';
import {faArrowCircleUp} from '@fortawesome/free-solid-svg-icons';
import {ChildrenOutletContexts} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

}
