<footer class="footer mt-auto py-3 menu-bg fixed-bottom">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <a class="text-muted" href="https://petrvich.eu" target="_blank">© Petr Vích 2019
                    - {{ currentYear }}</a>
            </div>
            <div class="col-12 col-md-6">
                <p class="text-muted text-end">{{appVersion}} - {{getFormattedBuildDate()}}</p>
            </div>
        </div>
    </div>

    <a href="javascript: void(0);" (click)="navigateToHeader()" class="back-to-top" *ngIf="isTopArrowVisible()">
        <fa-icon [icon]="faArrowCircleUp"></fa-icon>
    </a>
</footer>
