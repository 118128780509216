<div [id]="navigationItem.scrollTo" class="section">
    <div class="container">
        <div class="section-header">
            <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">{{navigationItem.title}}</h2>
        </div>

        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-sm-12 col-lg-6">
                    <label for="name" class="col-12 col-form-label"
                           [ngClass]="{ 'is-invalid': submitted && f.name.errors, 'is-valid': f.name.touched && !f.name.errors }">Jméno</label>
                    <div class="col-12">
                        <input type="text" class="form-control" style="margin-bottom: 0;" id="name"
                               formControlName="name" required
                               [ngClass]="{ 'is-invalid': submitted && f.name.errors, 'is-valid': f.name.touched && !f.name.errors }"/>
                        <div *ngIf="submitted && f['name'].errors" class="invalid-feedback float-right">
                            <div *ngIf="f['name'].errors.required">Vyplňte, prosím, jméno</div>
                        </div>
                    </div>
                </div>

                <div class="form-group col-lg-6 col-sm-12">
                    <label for="email" class="col-12 col-form-label"
                           [ngClass]="{ 'is-invalid': submitted && f.email.errors, 'is-valid': f.email.touched && !f.email.errors }">E-mail</label>
                    <div class="col-12">
                        <input type="email" class="form-control" style="margin-bottom: 0;" id="email"
                               formControlName="email" required="required"
                               [ngClass]="{ 'is-invalid': submitted && f.email.errors, 'is-valid': f.email.touched && !f.email.errors }"/>
                        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback float-right">
                            <div *ngIf="f['email'].errors.email">Email není ve správném formátu.</div>
                            <div *ngIf="f['email'].errors.required">Vyplňte, prosím, email</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="message" class="col-lg-11 col-sm-6 col-xs-6 col-form-label"
                       [ngClass]="{ 'is-invalid': submitted && f.message.errors, 'is-valid': f.message.touched && !f.message.errors }">Zpráva
                </label>
                <span class="col-lg-1 col-sm-6 col-xs-6 float-right">{{getMessageLength()}} / 4000</span>
                <div class="col-12">
            <textarea rows="10" maxlength="4000" class="form-control" style="margin-bottom: 0;" id="message"
                      formControlName="message" required="required"
                      [ngClass]="{ 'is-invalid': submitted && f.message.errors, 'is-valid': f.message.touched && !f.message.errors }"></textarea>
                    <div *ngIf="submitted && f['message'].errors" class="col-12 float-right invalid-feedback">
                        <div *ngIf="f['message'].errors.required">Vyplňte, prosím, text zprávy</div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="offset-lg-2 col-lg-4 col-12">
                    <ngx-recaptcha2 #captchaElem
                                    [siteKey]="siteKey"
                                    (reset)="handleReset()"
                                    (expire)="handleExpire()"
                                    (load)="handleLoad()"
                                    (success)="handleSuccess($event)"
                                    (error)="handleError()"
                                    [hl]="lang"
                                    formControlName="recaptcha"
                                    required
                                    [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors, 'is-valid': f.recaptcha.touched && !f.recaptcha.errors }">
                    </ngx-recaptcha2>
                    <div *ngIf="submitted && f['recaptcha'].errors" class="invalid-feedback" style="display:inline">
                        <div *ngIf="submitted && f['recaptcha'].errors.required">Potvrďte, že nejste robot, ale člověk
                        </div>
                    </div>
                </div>
                <button class="btn btn-success col-lg-4 col-12" type="submit">Odeslat</button>

            </div>


        </form>
    </div>
</div>
