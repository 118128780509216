<app-header></app-header>

<app-introduction class="component"></app-introduction>
<app-science class="component"></app-science>
<app-therapy class="component"></app-therapy>
<app-video class="component"></app-video>
<app-publication class="component"></app-publication>
<app-links class="component"></app-links>
<app-contact class="component"></app-contact>

<app-footer></app-footer>

