// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appVersion: '1.0.1',
    appBuildDate: '2024-04-01T17:58:55+00:00',
    gitCommitId: '0e0f0023185eb145eaac784e9547ed04fa25ab4e',
    navigation: {
        duration: 2000
    },
    mail: {
        url: 'http://send-email.petrvich.eu/api/',
        key: 'homosexualita',
        token: ''
    },
    videoUrl: 'https://static.homosexualita.petrvich.eu/videos'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
