import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faVideo} from '@fortawesome/free-solid-svg-icons';
import {environment} from "../../../environments/environment";


@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent extends Base implements OnInit {

    constructor(navigationService: NavigationService) {
        super(navigationService);
    }

    ngOnInit() {
        this.navigationItem = this.addToNavigation('Video', 'video', faVideo);
    }

    getVideoBaseUrl(): string {
        return environment.videoUrl;
    }
}
