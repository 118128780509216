import {Component, OnInit} from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faMedkit} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-therapy',
  templateUrl: './therapy.component.html',
  styleUrls: ['./therapy.component.scss']
})
export class TherapyComponent extends Base implements OnInit {

  constructor(navigationService: NavigationService) {
    super(navigationService);
  }

  ngOnInit() {
    this.navigationItem = this.addToNavigation('Terapie', 'therapy', faMedkit);
  }
}
