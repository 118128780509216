import { Component, OnInit } from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faLink} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent extends Base implements OnInit {


  constructor(navigationService: NavigationService) {
    super(navigationService);
  }

  ngOnInit() {
    this.navigationItem = this.addToNavigation('Odkazy', 'links', faLink);
  }

}
