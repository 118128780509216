<div [id]="navigationItem.scrollTo" class="section">
    <div class="container">
        <div class="section-header">
            <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">Porozumění přitažlivosti ke stejnému pohlaví</h2>
            <h3 class="section-title wow fadeIn" data-wow-delay="0.2s"></h3>
        </div>
        <div class="row">
            <video controls>
                <source src="{{getVideoBaseUrl()}}/video_1080.mp4" type="video/mp4">
                <source src="{{getVideoBaseUrl()}}/video_1080.webm" type="video/webm">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</div>
