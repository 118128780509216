<header id="home" class="header-area menu-bg" (scroll)="onScroll($event)">
    <div class="overlay"></div>
    <app-navigation></app-navigation>
    <div class="container">
        <div class="row space-100">
            <div class="col-lg-9 col-md-12 col-xs-12">
                <div class="contents">
                    <h2 class="head-title">Homosexualita</h2>
                    <p>
                        Tento web se snaží pomoci těm, kteří pociťují přitažlivost ke stejnému pohlaví a chtějí to
                        změnit. Snaží se jim ukázat možnosti terapie a poskytnout jim aktuální informace a kontakty, kde
                        a jak mohou nalézt pomoc.
                    </p>
                </div>
            </div>
        </div>
    </div>
</header>
