import {Component, OnInit, ViewChild} from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactMessage} from '../../model/contact-message';
import {ReCaptcha2Component} from 'ngx-captcha';
import {ContactService} from '../../service/contact/contact.service';
import {environment} from '../../../environments/environment';
import {ContactMessageData} from '../../model/contact-message-data';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends Base implements OnInit {

    submitted = false;
    contactForm: UntypedFormGroup;
    alerts: any[];

    public captchaIsLoaded = false;
    public captchaSuccess = false;
    public captchaIsExpired = false;
    public captchaResponse?: string;
    lang = 'cs';
    siteKey = '6LcU05kUAAAAAHQPZwScrlhIxIBZWDwMF_qKOceq';
    @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

    constructor(private formBuilder: UntypedFormBuilder, navigationService: NavigationService, private contactService: ContactService) {
        super(navigationService);
    }

    ngOnInit() {
        this.alerts = [];
        this.navigationItem = this.addToNavigation('Kontakt', 'contact', faEnvelope);
        this.createForm();
    }

    get f() {
        return this.contactForm.controls;
    }

    getMessageLength(): number {
        if (this.contactForm.value.message == null) {
            return 0;
        }
        return this.contactForm.value.message.length;
    }

    createForm() {
        this.contactForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            message: ['', [Validators.required, Validators.maxLength(4000)]],
            recaptcha: ['', Validators.required]
        });
        this.submitted = false;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.contactForm.invalid) {
            return;
        }

        const contactMessageData = new ContactMessageData();
        contactMessageData.message = this.contactForm.value.message;
        contactMessageData.name = this.contactForm.value.name;
        contactMessageData.email = this.contactForm.value.email;

        const contactMessage = new ContactMessage();
        contactMessage.name = this.contactForm.value.name;
        contactMessage.email = this.contactForm.value.email;
        contactMessage.token = environment.mail.token;
        contactMessage.client = environment.mail.key;
        contactMessage.data = contactMessageData;

        console.log('data to send', contactMessage);

        this.contactService.sendMail(contactMessage)
            .subscribe(response => {
                let messageType;
                if (response.success) {
                    messageType = 'success';
                } else {
                    messageType = 'error';
                }

                console.log('response contact form', response);
                this.alerts.push({
                    type: messageType,
                    msg: response.message,
                    timeout: 5000
                });
                this.contactForm.reset();
                this.submitted = false;
            });
    }

    handleReset(): void {
        this.captchaSuccess = false;
        this.captchaResponse = undefined;
        this.captchaIsExpired = false;
    }

    handleSuccess(captchaResponse: string): void {
        this.captchaSuccess = true;
        this.captchaResponse = captchaResponse;
        this.captchaIsExpired = false;
    }

    handleLoad(): void {
        this.captchaIsLoaded = true;
        this.captchaIsExpired = false;
    }

    handleExpire(): void {
        this.captchaSuccess = false;
        this.captchaIsExpired = true;
    }

    handleError(): void {

    }

    getCurrentResponse(): void {
        const currentResponse = this.captchaElem.getCurrentResponse();
        if (!currentResponse) {
            alert('There is no current response - have you submitted captcha?');
        } else {
            alert(currentResponse);
        }
    }

    getResponse(): void {
        const response = this.captchaElem.getResponse();
        if (!response) {
            alert('There is no response - have you submitted captcha?');
        } else {
            alert(response);
        }
    }

    reload(): void {
        this.captchaElem.reloadCaptcha();
    }

    getCaptchaId(): void {
        alert(this.captchaElem.getCaptchaId());
    }

    reset(): void {
        this.captchaElem.resetCaptcha();
    }

}
