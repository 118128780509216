import {Component, HostListener, OnInit} from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faArrowCircleUp} from "@fortawesome/free-solid-svg-icons";
import {ChildrenOutletContexts} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    faArrowCircleUp = faArrowCircleUp;
    currentYear = new Date().getFullYear();
    appVersion = environment.appVersion;
    appBuildDateString: string = environment.appBuildDate;

    constructor(private navigation: NavigationService) {
    }

    isTopArrowVisible(): boolean {
        return this.navigation.showNavigationBackground;
    }

    navigateToHeader() {
        document.querySelector('body')?.scrollIntoView({behavior: "smooth"});
        this.navigation.resetActiveItems();
    }

    getFormattedBuildDate(): string {
        const appBuildDate = new Date(this.appBuildDateString);
        return appBuildDate.toLocaleDateString() + ' ' + appBuildDate.toLocaleTimeString();
    }
}
