<nav class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar" [ngClass]="getNavClass()">
    <div class="container">
        <button (click)="showHamburgerMenu = !showHamburgerMenu;" class="navbar-toggler" type="button"
                                                                  data-toggle="collapse" data-target="#navbarCollapse"
                                                                  aria-controls="navbarCollapse" aria-expanded="false"
                                                                  aria-label="Toggle navigation">
            <fa-icon style="color: white;" [icon]="faBars"></fa-icon>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="showHamburgerMenu? 'show': ''">
            <ul class="navbar-nav mr-auto w-100 justify-content-end">
                <li *ngFor="let item of items" class="nav-item">
                    <a *ngIf="item.icon != null" class="nav-link page-scroll"
                                                 style="cursor: pointer" [ngClass]="item.active" (click)="navigateTo(item)">
                        <fa-icon [icon]="item.icon"></fa-icon>
                        {{item.title}}
                    </a>
                    <a *ngIf="item.icon == null" class="nav-link page-scroll"
                                                 style="cursor: pointer" [ngClass]="item.active" (click)="navigateTo(item)">
                        {{item.title}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
