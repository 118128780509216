import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {FooterComponent} from './pages/footer/footer.component';
import {IntroductionComponent} from './pages/introduction/introduction.component';
import {ScienceComponent} from './pages/science/science.component';
import {TherapyComponent} from './pages/therapy/therapy.component';
import {PublicationComponent} from './pages/publication/publication.component';
import {LinksComponent} from './pages/links/links.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AlertModule} from 'ngx-bootstrap/alert';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ModalModule} from 'ngx-bootstrap/modal';

import {ContactComponent} from './pages/contact/contact.component';
import {HrefLinkComponent} from './components/href-link/href-link.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxCaptchaModule} from 'ngx-captcha';
import {HttpClientModule} from '@angular/common/http';
import {VideoComponent} from "./pages/video/video.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HeaderComponent} from "./pages/header/header.component";

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        HeaderComponent,
        FooterComponent,
        IntroductionComponent,
        ScienceComponent,
        TherapyComponent,
        PublicationComponent,
        LinksComponent,
        ContactComponent,
        HrefLinkComponent,
        VideoComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        AlertModule.forRoot(),
        ReactiveFormsModule,
        NgxCaptchaModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
