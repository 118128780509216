import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ContactMessage} from '../../model/contact-message';
import {environment} from '../../../environments/environment';
import {ContactResponse} from '../../model/contact-response';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*'
    })
};

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(private http: HttpClient) {
    }

    public sendMail(message: ContactMessage): Observable<ContactResponse> {
        const url = environment.mail.url;
        return this.http.post<ContactResponse>(url, message, httpOptions);
    }
}
