<div [id]="navigationItem.scrollTo" class="section">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">{{navigationItem.title}}</h2>
    </div>
    <div class="row">
      <div class="col-12 content">
        V současnosti se uvádí dva přístupy k vnímání homosexuality: egosyntonní a egodystonní. Egosyntonní přístup
        vyjadřuje soulad s tím, co jedinec cítí a prožívá, jaké má představy, myšlenky, vlastnosti nebo chování a to vše
        vnímá a přijímá jako nedílnou součást jeho osobnosti. Jedinec je veden k tomu, aby vnímal, že je rád, že je
        homosexuál a nechce na tom nic měnit. Egodystonní přístup pak spočívá v tom, že jedinec své prožívání, pocity a
        chování vnitřně nepřijímá, nepovažuje tyto projevy za součást své identity, a vnitřně se od nich distancuje. To
        zpravidla znamená, že by si přál, aby jeho sexuální orientace byla jiná, nebo považuje svou orientaci za vadu či
        nemoc, jejíž projevy neprožívá jako projevy vlastní vůle, popřípadě si svou orientaci vůbec nepřiznává.
      </div>
      <div class="col-12 content">
        Drtivá většina homosexuálních jedinců zastává egodystonní přístup ke své homosexualitě a nesouhlasí se svou
        orientací a skrývají ji. Nikdo z nich, především zpočátku, když si svou sexuální orientaci uvědomí, není rád, že
        ho přitahují jedinci stejného pohlaví. LGBTI organizace ale působí a tlačí na homosexuály a celou společnost,
        aby zastávali jako jediný možný egosyntonní přístup. To znamená, aby homosexuální jedinci svou orientaci
        přijali, ztotožnili se s ní a byli na ni hrdí. Pokud se někdo rozhodne pro léčbu homosexuality nebo ji
        propaguje, ocitne se pod silným tlakem těchto organizací, je znevažován a zastrašován. Tyto kruhy nedokážou
        přijmout, že homosexualita je léčitelná a pokouší se za každou cenu zabránit léčbě, která by vedla ke změně.
      </div>
      <div class="col-12 content">
        Homosexualitu je možné překonat a jsou terapeuti, kteří pomáhají lidem, aby to dokázali. Může to být i dlouhý
        těžký proces. Terapie má své pozitivní výsledky, i když její výsledky , závisí případ od případu. Terapeuti,
        kteří pracují s klienty, aby překonali homosexualitu nehovoří u svých klientů o homosexualitě, ale hovoří o
        problému přitažlivosti ke stejnému pohlaví - SSA (Same Sex Attraction), který je následkem emočního zranění.
        Toto zranění se pak projevuje přitažlivostí ke stejnému pohlaví, ale jedná se o afektivní emocionální
        záležitost.
      </div>
      <div class="col-12 content">
        Je obtížné prokázat účinnost této terapie, protože je velmi náročná a mnoho lidí ji proto opustí a nedokončí.
        Mimo jiné terapeuti, kteří se jí zaobírají, nemají možnost vědecky publikovat své výsledky. U osob, které léčbu
        dokončí, je úspěch velmi vysoký. Přitažlivost ke stejnému pohlaví sice nemusí úplně vymizet, ale sníží se na
        velmi dobře kontrolovatelnou úroveň, která u těchto osob nevytváří problémy a dokážou vést normální
        heterosexuální život.
      </div>
    </div>
  </div>
</div>
