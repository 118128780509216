<div [id]="navigationItem.scrollTo" class="section">
    <div class="container">
        <div class="section-header">
            <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">{{ navigationItem.title }}</h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-12">
                <h3 class="btn btn-subtitle wow fadeInDown animated offset-lg-4 col-lg-4 col-sm-10 offset-sm-1 btn-subtitle-cursor">
                    České</h3>
                <div class="col-12 mb-3">
                    Terapie homosexuality – Rádce pro postižené a poradce <i>[Gerard J. M. van den Aardweg - nizozemský
                    křesťanský psycholog]</i>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <h3 class="btn btn-subtitle wow fadeInDown animated offset-lg-4 col-lg-4 col-sm-10 offset-sm-1 btn-subtitle-cursor">
                    Slovenské</h3>
                <div class=" mb-2">Slovensky je k dispozici několik publikací, které pro vnitřní potřebu vydala Linka Valentin</div>
                <div class="col-12 mb-3">
                    Čo homosexualita nie je. Čo homosexualita je. Ako sa dá homosexualita prekonať <i>[Gerard J. M. van
                    den Aardweg - nizozemský křesťanský psycholog]</i>
                </div>
                <div class="col-12 mb-3">
                    Kto som. Psychológia, viera a pritažlivosť k rovnakému pohlaviu <i>[Michelle A. Cretella, M.D.]</i>
                </div>
                <div class="col-12  mb-3">
                    Tretia cesta. Príbehy mužov a žien prežívajúcich homosexuálnu náklonosť <i>[Vitalij Luck]</i>
                </div>
                <div class="col-12 mb-3">
                    Príťažlivosť rovnakého pohlavia. Usmernenie pre rodičov a vychovávateľov <i>[J. Harvey a G.
                    Bradley]</i>
                </div>
                <div class="col-12 mb-3">
                    Cesta muža. Ako žíť s príťažlivosťou k rovnakému pohlaviu <i>Peter Smrek</i> -
                    <app-href-link
                        href="https://www.slovoplus.sk/homosexualne-citiaci-muz-ked-clovek-prijme-cestu-kriza-stava-sa-pre-neho-cestou-svatosti/"
                        [type]="typeExternal" titleText="rozhovor s autorem"></app-href-link>
                </div>
                <div class="col-12 mb-3">
                    <app-href-link
                        href="https://www.luc.sk/homosexualita-nemus%C3%AD-by%C5%A5-osudom" [type]="typeExternal"
                        titleText="Homosexualita nemusí byť osudom"></app-href-link>. Terapeutické rozhovory <i>[Dr. Joseph Nicolosi]</i>
                </div>
            </div>
        </div>
    </div>
</div>
