import {NavigationService} from '../service/navigation/navigation.service';
import {ItemNavigation} from '../model/item-navigation';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {HrefType} from '../model/href-type';

export class Base {

  navigationItem: ItemNavigation;
  typeEmail = HrefType.EMAIL;
  typeExternal = HrefType.EXTERNAL;
  typeFile = HrefType.FILE;

  constructor(protected navigationService: NavigationService) {
  }

  addToNavigation(title: string, scrollTo: string, icon?: IconDefinition): ItemNavigation {
    const item = new ItemNavigation();
    item.title = title;
    item.icon = icon;
    item.scrollTo = scrollTo;
    item.active = '';
    this.navigationService.addToNavigation(item);
    return item;
  }

  setAsActive(item: ItemNavigation) {
    this.navigationService.setItemAsActive(item);
  }
}
