import {Component, HostListener, OnInit} from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Base implements OnInit {

  constructor(navigationService: NavigationService) {
    super(navigationService);
  }

  ngOnInit() {
    // const item = this.addToNavigation('', 'home', 'fas fa-home');
    // this.setAsActive(item);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.pageYOffset > 50) {
      this.navigationService.showNavigationBackground = true;
    } else {
      this.navigationService.showNavigationBackground = false;
    }
    if (!this.navigationService.eventByClick) {
      this.setActiveOnScroll();
    }
  }

  setActiveOnScroll() {
    const self = this;
    this.navigationService.getItems().forEach(function (item) {
      const elem = document.getElementById(item.scrollTo);
      if (elem != null && elem.getBoundingClientRect().top > -5 && elem.getBoundingClientRect().top < 5) {
        self.navigationService.setItemAsActive(item);
      }
    });
  }

}
