import {IconDefinition} from '@fortawesome/free-solid-svg-icons';

export class ItemNavigation {

  title: string;
  icon: IconDefinition;
  scrollTo: string;
  active?: string;

}
