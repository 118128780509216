import { Component, OnInit } from '@angular/core';
import {Base} from '../base';
import {NavigationService} from '../../service/navigation/navigation.service';
import {faBook} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.scss']
})
export class PublicationComponent extends Base implements OnInit {


  constructor(navigationService: NavigationService) {
    super(navigationService);
  }

  ngOnInit() {
    this.navigationItem = this.addToNavigation('Publikace', 'publication', faBook);
  }

}
